<template>
  <section class="Header text-center mt-2 addin">

    <div v-if="user.information" class="mt-2">
      <b-row>
        <b-col cols="9" class="text-left">
          <UserInfo image="img/default-avatar.png" :primary="user.information.name" :secondary="user.information.organisation.name"></UserInfo>
        </b-col>
        <b-col cols="3">
          <b-dropdown
            variant="link"
            dropup right
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <i
                class="ms-Icon ms-Icon--CollapseMenu"
                style="vertical-align: middle;"
                aria-hidden="true"
              ></i>
            </template>
            <b-dropdown-item :href="sefos_url" target="_blank">{{
              $t("HEADER.ADMINISTRATION")
            }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="logout">
              {{ $t("HEADER.LOGOUT") }}</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-row>

      <b-modal id="about-modal" no-fade hide-header hide-footer centered>
        <div class="text-center">
          <p>
            SEFOS Outlook Addin
          </p>
          <p>
            Nubibus Software AB &copy; {{current_year}}
          </p>
          <p>
            {{showVersion}}
          </p>
        </div>
      </b-modal>

      <hr />
    </div>
  </section>
</template>
<script>
export default {
  components: { 
    UserInfo: () => import("@/components/Layout/UserInfo")
  },
  data() {
    return {
      mainProps: { width: 143, height: 143 }
    };
  },
  methods: {
    logout: function() {
      let self = this;
      this.$store.dispatch("logger/add",'logout');
      self.$store.dispatch("user/clearUserInformation");
      self.$store.dispatch('logger/add','logout finished');
      /*this.$http
        .get(this.user.hostname + "/logout")
        .then(function() {          
          self.$store.dispatch("user/clearUserInformation");
          self.$store.dispatch('logger/add','logout finished');
        })
        .catch(function(error) {
          if (error.response !== undefined) {
            self.$noty.error(self.$t(error.response.data));
          }
          self.$store.dispatch('logger/add','logout error');
        });*/
    }
  },
  computed: {
    current_year() {
      return new Date().getFullYear();
    },
    sefos_url() {
      return this.user.hostname.replace("/server/rest", "");
    },
    showVersion() {
      if (process.env.VUE_APP_GIT_COMMIT != "") {
        return "Version: " + process.env.VUE_APP_GIT_COMMIT;
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.$store.dispatch("logger/add",'init Header');
    console.log("Header mounted");
  }
};
</script>
